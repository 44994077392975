import { ALIGN, Radio, RadioGroup } from "baseui/radio"
import { StationCode } from "@today/lib"
import { useState } from "react"

type StationCodeFilterOption = "ALL" | StationCode

type StationCodeFilterProps = {
  option: StationCodeFilterOption
  setOption: (opt: StationCodeFilterOption) => void
  options: StationCode[]
}

export function useStationCodeFilter() {
  const [option, setOption] = useState<StationCodeFilterOption>("ALL")
  return { option, setOption }
}

export function StationCodeFilter({
  option,
  setOption,
  options,
}: StationCodeFilterProps) {
  return (
    <>
      <div className="ml-2 font-bold">배송구역 필터</div>
      <div className="ml-2">
        <RadioGroup
          value={option}
          onChange={(e) =>
            setOption(e.currentTarget.value as StationCodeFilterOption)
          }
          name="station-code"
          align={ALIGN.horizontal}
        >
          <Radio value="ALL">전체</Radio>
          {options.map((c) => (
            <Radio key={c} value={c}>
              {c}
            </Radio>
          ))}
        </RadioGroup>
      </div>
    </>
  )
}
