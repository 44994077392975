import { Order } from "@today/api/taker"
import { RegionSet } from "@today/api/tracker"
import {
  getCustomer,
  getStationCode as getStationCodeFromRegionSetName,
} from "@today/lib"

export function getStationCode(order: Order, regionSets?: RegionSet[]): string {
  const regionId = getCustomer(order).lastMileInfo?.regionId
  const regionSet = regionSets
    ?.filter((rs) => rs.regionSetPolicyType === "TODAY_EVENING")
    .find((rs) => rs.regionIds.includes(regionId))
  return getStationCodeFromRegionSetName(regionSet?.regionSetName)
}
